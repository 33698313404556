<template>
    <div class="relative pb-5 mt-5 ml-3 mr-3 bg-white rounded-lg shadow-lg">
        <LoadingComponent :isLoading="loading" />
        <div class="content">
            <div class="top-content">
                <div class="title text-black-blod-18">會員最新消息資訊</div>
                <el-form ref="formRefDom" :model="inputs" :rules="rules">
                    <div class="flex inputs">
                        <div class="input">
                            <div class="label">內容</div>
                            <el-input
                                v-model="inputs.text"
                                maxlength="100"
                                show-word-limit
                                type="textarea"
                                :autosize="{ minRows: 6 }"
                                class="w-full"
                            ></el-input>
                        </div>
                        <div class="input">
                            <div class="label">使用期間</div>
                            <el-select
                                v-model="duration"
                                class="w-full"
                                placeholder="請選擇"
                                @change="changeDuration"
                            >
                                <el-option
                                    v-for="(item, idx) in duringOption"
                                    :key="idx"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                            <div class="mt-5">
                                <div class="label">期間範圍</div>
                                <div class="flex items-center">
                                    <el-date-picker
                                        v-model="inputs.start_date"
                                        type="date"
                                        placeholder="選擇開始日期"
                                        value-format="yyyy-MM-dd"
                                        :picker-options="startDateOptions"
                                        :disabled="duration == false"
                                    >
                                    </el-date-picker>
                                    <span class="mx-5">至</span>
                                    <el-date-picker
                                        v-model="inputs.end_date"
                                        value-format="yyyy-MM-dd"
                                        type="date"
                                        placeholder="選擇結束日期"
                                        :picker-options="endDateOptions"
                                        :disabled="duration == false"
                                    >
                                    </el-date-picker>
                                </div>
                            </div>
                        </div>

                        <div class="input"></div>
                    </div>
                </el-form>
            </div>
        </div>
        <div class="actions">
            <button
                class="mr-5 black-btn-outline-lg text-md"
                @click="goBackList"
            >
                返回列表
            </button>
            <el-button
                v-permission="['create', 'update']"
                class="orange-btn-800-lg text-md"
                :disabled="disabled"
                @click="onSubmit"
                >{{
                    route.params.id !== undefined ? "儲存變更" : "新增"
                }}</el-button
            >
        </div>
    </div>
</template>


<script >
import { ref, reactive, computed, onActivated, onMounted, watch, getCurrentInstance, defineExpose, onBeforeUnmount, nextTick } from "@vue/composition-api";
import { useRoute, useRouter } from "vue2-helpers/vue-router";
import { handleErrorMessage } from "@/service/errorMessage";
// 旋轉動畫組件
import LoadingComponent from "@/components/Loading.vue";
const __sfc_main = {
  name: "AddMemberNews"
};
__sfc_main.setup = (__props, __ctx) => {
  const router = useRouter();
  const route = useRoute();

  // 取得全域 this 方法 ex: this.$api, this.$message
  const {
    proxy
  } = getCurrentInstance();

  // 使用期間選項
  const duringOption = computed(() => [{
    label: "不限使用期間",
    value: false
  }, {
    label: "限定使用期間",
    value: true
  }]);

  // 判斷是否有限制使用期間
  const duration = ref(false);

  // 表單 dom
  const formRefDom = ref(null);

  // 表單驗證
  const rules = ref({
    text: [{
      required: true,
      message: "請輸入內容",
      trigger: "blur"
    }]
  });
  const startDateOptions = computed(() => {
    const end_date = inputs.value.end_date ? new Date(inputs.value.end_date).getTime() : "";
    return {
      disabledDate(time) {
        if (!end_date) {
          return false;
        }
        return time.getTime() > end_date;
      }
    };
  });
  const endDateOptions = computed(() => {
    const start_date = new Date(inputs.value.start_date).getTime();
    return {
      disabledDate(time) {
        return time.getTime() < start_date;
      }
    };
  });
  const disabled = computed(() => {
    const {
      end_date,
      start_date,
      text
    } = inputs.value;
    const isfillDate = end_date || start_date ? true : false;
    if (text) {
      if (duration.value) {
        if (isfillDate) {
          return false;
        }
        return true;
      }
      return false;
    }
    return true;
  });
  const loading = ref(false);
  // 輸入匡狀態
  const inputs = ref({
    end_date: "",
    start_date: "",
    text: ""
  });

  // 返回列表
  function goBackList() {
    router.push({
      name: "member_news"
    });
  }

  /**
   * 取得資料
   */
  async function getData() {
    try {
      const {
        data
      } = await proxy.$api.GetMemberNewsApi(route.params.id);
      if (data.start_date || data.end_date) {
        duration.value = true;
      }
      inputs.value = {
        text: data.text,
        start_date: data.start_date,
        end_date: data.end_date
      };
    } catch (err) {
      proxy.$message({
        type: "error",
        message: "取得資料失敗"
      });
    }
  }

  // 新增 API
  async function createNews() {
    const {
      end_date,
      start_date,
      text
    } = inputs.value;
    const req = {
      end_date,
      start_date,
      text
    };
    try {
      await proxy.$api.CreateMemberNewsApi(req);
      proxy.$message({
        type: "success",
        message: "新增成功"
      });
      goBackList();
    } catch (err) {
      const errorMessage = handleErrorMessage(err.response.data.errors);
      proxy.$message({
        type: "error",
        message: errorMessage ? errorMessage : "新增失敗"
      });
    }
  }

  /**
   * 更新
   */
  async function updateNews() {
    try {
      await proxy.$api.UpdateMemberNewsApi(route.params.id, inputs.value);
      proxy.$message({
        type: "success",
        message: "更新成功"
      });
      goBackList();
    } catch (err) {
      console.log(err);
      proxy.$message({
        type: "error",
        message: "更新資料失敗"
      });
    }
  }

  /**
   * 表單發送
   */
  async function onSubmit() {
    try {
      if (formRefDom.value === null) {
        console.log(formRefDom.value);
        return proxy.$message({
          type: "error",
          message: "表單驗證失敗"
        });
      }
      // 等待表單驗證是否成功 try catch 會自動判斷是 true 或 false 因次不用寫 if 判斷
      await formRefDom.value.validate();
      // 判斷是否為更新畫面
      if (route.name === "edit_member_news") {
        await updateNews();
      } else {
        await createNews();
      }
    } catch (err) {
      console.log(err);
      proxy.$message({
        type: "error",
        message: "表單驗證失敗"
      });
    }
  }
  function changeDuration(val) {
    if (!val) {
      inputs.value.start_date = "";
      inputs.value.end_date = "";
    }
  }
  onMounted(async () => {
    if (route.params.id !== undefined && route.name === "edit_member_news") {
      await getData();
    }
  });
  return {
    route,
    duringOption,
    duration,
    formRefDom,
    rules,
    startDateOptions,
    endDateOptions,
    disabled,
    loading,
    inputs,
    goBackList,
    onSubmit,
    changeDuration
  };
};
__sfc_main.components = Object.assign({
  LoadingComponent
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss" scoped>
.myTable {
    width: 100%;
}
.content {
    .title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-left: 20px;
    }
    .top-content {
        padding: 30px 5% 0;
        .inputs {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 20px 20px 30px;
            .input {
                width: 48%;
                padding: 10px 0px;
                .profile {
                    display: flex;
                    align-items: center;
                    .profile-img {
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                    }
                    .name {
                        color: #ff5733;
                        text-decoration: underline;
                        font-size: 14px;
                        cursor: pointer;
                        margin-left: 20px;
                    }
                }
            }
        }
    }
    .bottom-content {
        padding: 30px 5%;
        .upload-area {
            display: flex;
            padding: 20px 20px 30px;
            .left {
                max-width: 50%;
                flex: 1;
                margin-right: 30px;
            }
            .right {
                flex: 1;
            }
        }
    }
    .label {
        color: rgb(133, 130, 130);
        margin-bottom: 5px;
        font-size: 14px;
    }
}

.display-img {
    margin-top: 15px;
    position: relative;
    padding: 30px;
    .error {
        width: 24px;
        height: 24px;
        background: #000;
        border-radius: 50%;
        position: absolute;
        top: 20px;
        right: 20px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
}
::v-deep .el-upload-list {
    display: none;
}
.actions {
    width: 100%;
    display: flex;
    justify-content: center;
}
</style>
